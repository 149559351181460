import { currentUser } from '@mfl/platform-shell';

import { groupsManager } from '../groups-manager';
import { useRequest } from '../composables/use-request';

const defaultValues = [
  {
    groupId: '',
    name: '',
    isPlaceholder: false,
    isDefault: false,
    signatureId: '',
    replySignatureId: '',
    forwardSignatureId: '',
  },
];

const domainGroups = useRequest(async () => {
  return await groupsManager.getGroupList(currentUser.domainId);
}, defaultValues);

export default domainGroups;
