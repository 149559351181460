export default {
  header: 'Links',
  addLink: 'Add Link',
  emptyStateHeader: 'Shorten, Customize, and Track Your Links!',
  emptyStateText: 'Start by creating your first link',
  dialogSaveButton: 'Save',
  dialogTitleEdit: 'Edit Link',
  dialogTitleAdd: 'Link details',
  tableTitleColumn: 'Title',
  tableUrlColumn: 'Destination URL',
  tableLinkColumn: 'Short URL',
  tableClicksColumn: 'Clicks + scans',
  tableCreatedColumn: 'Created at',
  menuButtonEdit: 'Edit Link',
  menuButtonDelete: 'Delete Link',
  menuButtonGenerateQR: 'Generate QR',
  copyLink: 'Copy Link',
  copyQRCode: 'Copy QR code',
  download: 'Download',
  copiedLink: 'Copied!',
  dialogTitleDelete: 'Delete link',
  dialogDeleteButton: 'Delete',
  dialogTextDelete: `Deleting this link will remove all associated data.`,
  dialogQuestionDelete: 'Are you sure you want to proceed with deletion?',
  customLinkWarning:
    'This link is also attached to a survey, deleting it will change the share link for this survey to its default.',
  slugValidationMessage:
    'Input must be alphanumeric, can include hyphens, and be up to 20 characters long.',
  slugUniqueMessage: 'Short link has to be unique, try changing it',
  addUrl: 'Add destination URL',
  urlIsInvalid: 'URL is invalid',
  urlLabel: 'Destination URL',
  urlPlaceholder: 'Enter or paste your link',
  titleLabel: 'Title',
  titlePlaceholder: 'Enter a title for your link',
  slugLabel: 'Short link',
  slugPlaceholder: 'Enter slug',
  generalError: 'Something went wrong, please try again later',
};
