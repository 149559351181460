import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './error-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'error',
  mainComponent: mfe,
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

export { bootstrap, mount, unmount };
export * from './api';
