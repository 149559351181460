import {
  LongAction,
  settingsGateway,
  SpecificatorsWithStatus,
} from '@msl/settings-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';

import {
  DomainBasicInfoModel,
  EmployeePortalSettingsModel,
  DomainSyncAndAutomationModel,
  GeneralDomainInfo,
  DomainGoogleAnalyticsModel,
  // LongAction,
  SyncTask,
  EmailVerification,
  getInvitationTrustPolicy,
} from './settings-types';

//TODO: get rid of this manger in future
// the main goal of this manager is not make sure settingsGateway is initialized before being used
class SettingsManager {
  private async assertInitialized() {
    await settingsGateway.init(auth4Sdks());

    return settingsGateway;
  }

  public async getGeneralDomainInfo(): Promise<GeneralDomainInfo> {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getDomainGeneralInfo({});
    if (error) {
      throw new Error(error);
    }

    if (!result) {
      throw new Error('no result');
    }

    return result as GeneralDomainInfo;
  }

  public async getBasicDomainInfo(): Promise<DomainBasicInfoModel> {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getDomainBasicInfo({});
    if (error) {
      throw new Error(error);
    }

    if (!result) {
      throw new Error('no result');
    }

    return result as DomainBasicInfoModel;
  }

  public async updateBasicDomainInfo(data: DomainBasicInfoModel) {
    const gw = await this.assertInitialized();
    const { error } = await gw.updateDomainBasicInfo({
      data,
    });
    if (error) {
      throw new Error(error);
    }
  }

  public async getEmployeePortalSettings(): Promise<EmployeePortalSettingsModel> {
    const gw = await this.assertInitialized();

    const { error, result } = await gw.getEmployeePortalSettings({});
    if (error) {
      throw new Error(error);
    }

    if (!result) {
      throw new Error('no result');
    }

    //properties are checked in gateway
    return result as unknown as EmployeePortalSettingsModel;
  }

  public async getDomainSyncAutomationInfo(): Promise<DomainSyncAndAutomationModel> {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getDomainSyncSettings({});
    if (error) {
      throw new Error(error);
    }
    if (!result) {
      throw new Error('No domain sync settings loaded');
    }

    return result as DomainSyncAndAutomationModel;
  }

  public async updateDomainSyncAutomationInfo(
    data: DomainSyncAndAutomationModel
  ) {
    const gw = await this.assertInitialized();
    const { error } = await gw.updateDomainSyncSettings({
      data,
    });
    if (error) {
      throw new Error(`${error}`);
    }
  }

  public async updateEmployeePortalSettings(data: EmployeePortalSettingsModel) {
    const gw = await this.assertInitialized();
    const { error } = await gw.updateEmployeePortalSettings({
      data,
    });

    if (error) {
      throw new Error(error);
    }
  }

  public async getDomainAnalyticsSettings(): Promise<DomainGoogleAnalyticsModel> {
    const gw = await this.assertInitialized();

    const { error, result } = await gw.getDomainGoogleAnalyticsSettings({});
    if (error) {
      throw new Error(error);
    }

    if (!result) {
      throw new Error('no result');
    }

    return result as DomainGoogleAnalyticsModel;
  }

  public async updateDomainAnalyticsSettings({
    data,
  }: {
    data: DomainGoogleAnalyticsModel;
  }) {
    const gw = await this.assertInitialized();

    const { error } = await gw.updateDomainGoogleAnalyticsSettings({
      data,
    });
    if (error) {
      throw new Error(error);
    }
  }

  public async removeDomainIntegration() {
    const gw = await this.assertInitialized();
    const { error } = await gw.removeDomainIntegration({});
    if (error) {
      throw new Error(error);
    }
  }

  public async updateDomainName({ domainName }: { domainName: string }) {
    const gw = await this.assertInitialized();
    const { error } = await gw.updateDomainName({ domainName });
    if (error) {
      throw new Error(error);
    }
  }

  public async updateOrganizationName({
    organizationName,
  }: {
    organizationName: string;
  }) {
    const gw = await this.assertInitialized();
    const { error } = await gw.updateOrganizationName({ organizationName });
    if (error) {
      throw new Error(error);
    }
  }

  public async sendTestInviteEmail({ employeeId }: { employeeId: string }) {
    const gw = await this.assertInitialized();
    await gw.sendTestInviteEmail({
      employeeId,
    });
  }

  public async getInviteEmailPreview() {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getInviteEmailPreview({});
    if (error) {
      return error;
    }
    return result || '';
  }

  public async getLongActions() {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getLongActions({});
    if (error || !result) {
      throw new Error(error);
    }

    return result as Array<LongAction>;
  }

  public async getSpecificators(forceReload = false) {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getSpecificators({ forceReload });
    if (error || !result) {
      throw new Error(error);
    }

    return result as SpecificatorsWithStatus;
  }

  public async runSync(unselectedSpecificators?: string[]) {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.runSync({ unselectedSpecificators });
    if (error) {
      throw new Error(error);
    }
    return result as SyncTask;
  }

  public async sendVerificationEmail() {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.sendVerificationEmail({});
    if (error) {
      throw new Error(error);
    }
    return result as EmailVerification;
  }

  public async getInvitationTrustPolicy() {
    const gw = await this.assertInitialized();
    const { error, result } = await gw.getInvitationTrustPolicy({});
    if (error) {
      throw new Error(error);
    }
    return result as getInvitationTrustPolicy;
  }
}

export const settingsManager = new SettingsManager();
