<script setup lang="ts">
import { computed, toValue } from 'vue';
import strings from '../../../../settings-strings';

import {
  WsSelect,
  WsSelectHeader,
  WsSelectOption,
  WsToggle,
  WsInput,
} from '@mfl/common-components';

import { AnalyticsFieldName } from '../../../../settings-types';
import {
  ensureWrappedWithBraces,
  isInBraces,
  stripBraces,
} from '../../../../settings-utils';

const props = defineProps<{
  name: AnalyticsFieldName;
  placeholder: string;
  label: string;
  tooltip?: string;
  options: Array<{
    header: string;
    options: Array<{
      value: string;
      label: string;
    }>;
  }>;
  toggleAid: string;
  inputAid: string;
  selectAid: string;
}>();

const model = defineModel<string>({
  required: true,
});

const localValue = computed<{
  value: string;
  useVariable: boolean;
}>({
  get() {
    const useVariable = isInBraces(model.value);
    return {
      value: stripBraces(model.value),
      useVariable,
    };
  },
  set(v) {
    model.value = v.useVariable
      ? ensureWrappedWithBraces(v.value)
      : stripBraces(v.value);
  },
});

let beforeToggleValue = '';

const localOptions = computed(() =>
  props.options.map(({ header, options }) => {
    return {
      header,
      options: options.map((o) => o.value),
    };
  })
);

const localLabels = computed(() =>
  props.options.reduce(
    (acc, { options }) => {
      return options.reduce((acc, o) => {
        acc[o.value] = o.label;
        return acc;
      }, acc);
    },
    { '{{}}': strings.select, '': strings.select } as Record<string, string>
  )
);

const handleUseVariableToggle = (v: boolean) => {
  const oldValue = toValue(localValue).value;
  if (v) {
    localValue.value = {
      useVariable: true,
      value: beforeToggleValue,
    };
  } else {
    localValue.value = {
      value: beforeToggleValue,
      useVariable: v,
    };
  }
  beforeToggleValue = oldValue;
};

const handleSelectChange = (v: unknown) => {
  localValue.value = {
    ...localValue.value,
    value: v as string,
  };
};
</script>
<template>
  <div class="analytics-field-select-container">
    <div class="analytics-field-select-label-toggle-container">
      <div class="analytics-field-select-label-container">
        <label class="analytics-field-select-label text-sm">{{
          props.label
        }}</label>
      </div>
      <div class="analytics-field-select-toggle-container">
        <p class="analytics-general-form-field-wrapper-top-right-wrapper-label">
          {{ strings.analyticsGeneralCompanyFieldToggleLabel }}
        </p>
        <ws-toggle
          v-model:model-value="localValue.useVariable"
          :aid="props.toggleAid"
          @update:model-value="handleUseVariableToggle"
        />
      </div>
    </div>
    <div class="analytics-field-select-control-container">
      <ws-select
        v-if="localValue.useVariable"
        :model-value="localValue.value"
        :option-label="(v) => localLabels[v]"
        :option-key="(v) => v"
        :aid="props.selectAid"
        @update:model-value="handleSelectChange"
      >
        <template v-for="group in localOptions" :key="group.header">
          <template v-if="group.options?.length > 0">
            <WsSelectHeader>{{ group.header }}</WsSelectHeader>
            <WsSelectOption
              v-for="opt in group.options"
              :key="opt"
              :value="opt"
            >
              {{ localLabels[opt] }}
            </WsSelectOption>
          </template>
        </template>
      </ws-select>
      <ws-input
        v-else
        v-model="model"
        :aid="props.inputAid"
        size="md"
        :placeholder="props.placeholder"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.analytics-field-select-container {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .analytics-field-select-label-toggle-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .analytics-field-select-label-container {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: flex-start;
    }

    .analytics-field-select-toggle-container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 10px;
    }
  }

  .analytics-field-select-control-container {
    min-height: 65px;
    height: 60px;
  }
}
</style>
