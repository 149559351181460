<script setup lang="ts">
import { computed, ref, Component } from 'vue';

import Error404 from './components/error-404.vue';
import Error401 from './components/error-401.vue';
import Error500 from './components/error-500.vue';
import { ErrorType } from './api.types';

const type = ref<ErrorType>(ErrorType.NotFound);

const typeToComponentMap: Record<ErrorType, Component> = {
  [ErrorType.NotFound]: Error404,
  [ErrorType.Unauthorized]: Error401,
  [ErrorType.General]: Error500,
};

const ErrorComponent: Component | null = computed(() => {
  return typeToComponentMap[type.value] || null;
});
</script>

<template>
  <ErrorComponent v-if="ErrorComponent" />
</template>
