<script setup lang="ts">
import { computed, toValue } from 'vue';

import { WsButton, WsTooltip, WsAlert } from '@mfl/common-components';
import { currentUser } from '@mfl/platform-shell';
import { IntegrationType } from '@msl/settings-gateway-sdk';

import { UserEntitlements } from '../../../../settings-types';
import vLoading from '../../../../directives/loading';

import domainSyncAutomationModel from '../../../../composables/domain-sync-automation';

import generalDomainInfo from '../../../../composables/domain-general-info';
import { getWebAppUrl } from '../../../../settings-utils';
import { openDepartmentsDialog } from '../departments-dialog/departments-dialog';
import longActionModel, {
  hasActionsInProgress,
  isSyncRunning,
} from '../../../../composables/long-action-model';
import specificatorsModel, {
  STATUS,
} from '../../../../composables/specificators-model';

import strings from '../../../../settings-strings';
import runSyncModel from '../../../../composables/run-sync-model';
import { BoolSelectUpgradableOptions } from '../../../../components/select/select-types';
import BoolSelect from '../../../../components/select/bool-select.vue';

const model = defineModel<boolean>();

const props = defineProps<{
  hasSelectedDepartments: boolean;
  securityGroupName: string;
  hasSecurityGroup: boolean;
  integrationError: string;
}>();

const defaultSpecificatorType = computed(() => {
  if (generalDomainInfo.data.integrationType === IntegrationType.GOOGLE) {
    return 'orgDepartment';
  }
  return 'department';
});

const specificatorType = computed(() => {
  const syncAndAutomation = toValue(domainSyncAutomationModel.model);

  const result =
    syncAndAutomation.sync.specificators.type || defaultSpecificatorType.value;
  if (result === 'Department') {
    return result.toLowerCase();
  }

  return result;
});

const specificatorTypeName = computed(() => {
  const t = toValue(specificatorType);
  switch (t) {
    case 'orgDepartment':
    case 'department':
      return 'departments';
    case 'orgUnitPath':
      return 'Organization Unit';
    case 'orgCostCenter':
      return 'Cost Center';
    default:
      return t;
  }
});

const syncTypeSelectDisabledTooltipText = computed(() => {
  if (toValue(hasRunningLongActions)) {
    if (isSyncRunning(longActionModel.data)) {
      return strings.syncInProgressTooltipMessage;
    }
    return strings.longActionInProgressTooltip;
  }
  return '';
});

const upgradePlanRequired = computed(
  () => !currentUser.isEntitledTo(UserEntitlements.SETTINGS_AUTOMATIONS)
);

const selectedSpecificatorCountText = computed(() => {
  if (props.hasSelectedDepartments && specificatorsModel.data.specificators) {
    const selectedTypeSpecificatorsList =
      specificatorsModel.data.specificators.find(
        (s) =>
          s.departmentName ==
          domainSyncAutomationModel.model.value.sync.specificators.type
      );
    return `${
      domainSyncAutomationModel.model.value.sync.specificators.values.length
    }/${
      selectedTypeSpecificatorsList?.entries?.length || 0
    } ${specificatorTypeName.value.toLowerCase()} selected`;
  }
  return '';
});

const showIntegrationAlert = computed(() => {
  return (
    props.integrationError == 'security_group_not_found' ||
    props.integrationError == 'missing_privileges'
  );
});

const syncOptions = computed(() => {
  return [
    {
      value: 'off',
      label: strings.syncAll,
    },
    {
      value: 'on',
      label: props.hasSecurityGroup
        ? `${strings.syncFromSecurityGroup}  ${props.securityGroupName}`
        : strings.syncSpecific,
      requiresUpgrade: toValue(upgradePlanRequired),
    },
  ] as BoolSelectUpgradableOptions;
});

const integrationAlertText = computed(() => {
  if (props.integrationError == 'security_group_not_found') {
    return strings.securityGroupNotFoundError;
  }
  if (props.integrationError == 'missing_privileges') {
    return strings.missingPrivilegesError;
  }
  return '';
});

const handleUpgradePlan = () => {
  if (toValue(upgradePlanRequired)) {
    window.open(getWebAppUrl() + '#upgrade', '_blank');
  }
};

const handleUpdate = async (v: unknown) => {
  const val = !!v;
  domainSyncAutomationModel.onChange({
    ...domainSyncAutomationModel.model.value,
    sync: {
      ...domainSyncAutomationModel.model.value.sync,
      syncBySpecificators: val,
    },
  });

  if (!val) {
    if (generalDomainInfo.data.requireFinishIntegration) {
      await domainSyncAutomationModel.triggerPartialUpdate!([
        'sync.syncBySpecificators',
      ]);
      await runSyncModel.triggerFetch([]);
      await generalDomainInfo.triggerFetch();
      await longActionModel.triggerFetch();
    }
  } else {
    await specificatorsModel.triggerFetch();

    if (specificatorsModel.data.status !== STATUS.FINISHED) {
      await longActionModel.triggerFetch();
    }
  }
};

const openDepartmentsDialogHandler = async () => {
  await specificatorsModel.triggerFetch();
  if (specificatorsModel.data.status !== STATUS.FINISHED) {
    await longActionModel.triggerFetch();
  } else {
    await openDepartmentsDialog({
      specificatorTypeTitle: specificatorTypeName.value.toLowerCase(),
    });
  }
};

const hasRunningLongActions = computed(() =>
  hasActionsInProgress(longActionModel.data)
);

const manageDepartmentsAvailable = computed(
  () =>
    toValue(model) && !toValue(hasRunningLongActions) && !props.hasSecurityGroup
);

const disableSyncType = computed(
  () => toValue(hasRunningLongActions) || props.hasSecurityGroup
);

const showNoDepartmentsSelected = computed(() => {
  return !!(
    !props.hasSelectedDepartments &&
    !props.hasSecurityGroup &&
    toValue(model) &&
    !toValue(hasRunningLongActions)
  );
});

const showSelectedDepartmentsCount = computed(() => {
  return !!(
    toValue(model) &&
    props.hasSelectedDepartments &&
    !toValue(hasRunningLongActions) &&
    !props.hasSecurityGroup
  );
});
</script>

<template>
  <div class="sync-type-select-wrapper">
    <div class="button-wrapper">
      <ws-button
        v-if="manageDepartmentsAvailable"
        :label="strings.manage"
        aid="MANAGE_DEPARTMENTS_LIST_BUTTON"
        class="button-position"
        variant="text"
        color="primary"
        @click="openDepartmentsDialogHandler"
      />
      <div
        v-else-if="hasRunningLongActions"
        v-loading="true"
        class="loading-wrapper"
      ></div>
    </div>
    <ws-tooltip
      v-if="hasRunningLongActions"
      aid="LONG_ACTION_IN_PROGRESS_TOOLTIP"
      position="top"
    >
      {{ syncTypeSelectDisabledTooltipText }}
    </ws-tooltip>

    <BoolSelect
      :model-value="model"
      aid="SYNC_TYPE_SELECT"
      :options="toValue(syncOptions)"
      :disabled="disableSyncType"
      @upgrade-plan="handleUpgradePlan"
      @update:model-value="handleUpdate"
    />
    <div class="bottom-wrapper">
      <p v-if="showNoDepartmentsSelected" class="text-danger">
        {{ strings.noDepartmentsSelected }}
      </p>
      <p v-else-if="showSelectedDepartmentsCount" class="text-information">
        {{ selectedSpecificatorCountText }}
      </p>
      <ws-alert
        v-model:open="showIntegrationAlert"
        type="danger"
        variant="outlined"
        :closable="false"
        :block-ctions="false"
        aid="INTEGRATION_ERROR_ALERT"
        >{{ integrationAlertText }}
      </ws-alert>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.sync-type-select-wrapper {
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    .button-position {
      position: absolute;
      top: -25px;
    }

    .loading-wrapper {
      position: absolute;
      top: -23px;
      right: -10px;

      &::after {
        background-color: transparent;
      }

      &::before {
        width: 17px;
        height: 17px;
      }
    }
  }

  .bottom-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-top: 10px;
  }
}

.loading-wrapper {
  height: 30px;
  width: 30px;
}

.text-info {
  color: #828282;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
</style>
