<script setup lang="ts">
import { WsInput } from '@mfl/common-components';
import { IntegrationType } from '@msl/settings-gateway-sdk';
import strings from '../../../../settings-strings';
const model = defineModel<string>({ required: true });

const props = defineProps<{
  isIntegrated: boolean;
  connectedBy: {
    type: number;
    value: string;
  };
  error?: string;
}>();
</script>
<template>
  <div class="edit-domain-name-control">
    <div v-if="props.isIntegrated" class="edit-domain-name-control-integrated">
      <div class="integration-decr">
        <div
          :class="[
            'integration-type',
            {
              microsoft: connectedBy.type == IntegrationType.MICROSOFT,
              google: connectedBy.type == IntegrationType.GOOGLE,
            },
          ]"
        >
          &nbsp;
        </div>
        <div class="font-semibold">
          {{
            connectedBy.type == IntegrationType.GOOGLE
              ? strings.connectedByGoogle
              : strings.connectedByMicrosoft
          }}
        </div>
        <div>{{ connectedBy.value }}</div>
      </div>
    </div>
    <ws-input
      v-else
      v-model="model"
      aid="EDIT_DOMAIN_NAME_INPUT"
      :error="props.error"
      size="md"
      class="edit-domain-input"
    />
  </div>
</template>
<style>
.edit-domain-name-control-integrated {
  display: flex;
  justify-content: space-between;
}

.integration-decr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 48px;
}

.integration-type {
  background-repeat: no-repeat;
  flex: 0 0 100%;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
  width: 48px;
  background-position: center;
}

.microsoft {
  background-image: url('../../../../assets/imgs/microsoft.svg');
}

.google {
  background-image: url('../../../../assets/imgs/google.svg');
}
</style>
