import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import { initMenu } from './menu/init-menu';
import mfe from './core-navigation-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'core-navigation',
  mainComponent: mfe,
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);
    initMenu();
  },
});

export { bootstrap, mount, unmount };

// public API
export * from './api.js';
