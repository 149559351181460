<script setup lang="ts">
import { computed, shallowRef } from 'vue';

import { navigationItems, navigationItemsOverride } from '../api';
import { NavigationItem } from '../api.types';
import NavigationGroup from './navigation-group.vue';
import { GroupedItems } from './navigation-items.types';

const ROOT = 'root';

/**
 * Group items according to thier parentKey\
 * If an item does not have a parent, it is placed in the ROOT group
 *
 * This map is passed down to the group component\
 * The group component passes it to the item component\
 * The item component passes it down to the group.. recursive
 */
function constructGroups(items: NavigationItem[]): GroupedItems {
  const groups = new GroupedItems();

  const processedKeys = new Set<string>();

  for (const item of items) {
    // check for duplicate
    if (processedKeys.has(item.key)) {
      // Give an error unless the item is a collaborated group (see relevant readme section)
      if (!groups.has(item.key))
        console.error(`Duplicate navigation item "${item.key}"`);

      continue;
    }

    processedKeys.add(item.key);

    // Add item to a new or existing group
    const groupKey = item.parentKey || ROOT;
    const group = groups.get(groupKey);

    if (group) {
      group.push(item);
    } else {
      groups.set(groupKey, [item]);
    }
  }

  console.debug('core-navigation: groups', groups);

  return groups;
}

// Global menu items
const groups = shallowRef<GroupedItems>(new GroupedItems());
navigationItems.subscribe((items) => {
  console.debug('core-navigation: items changed', items);
  groups.value = constructGroups(items);
});

// Override menu items
const overrideGroups = shallowRef<GroupedItems>(new GroupedItems());
navigationItemsOverride.subscribe((items) => {
  console.debug('core-navigation: override items changed', items);
  overrideGroups.value = constructGroups(items);
});

// Show the override items if there are any
const finalGroups = computed(() => {
  if (overrideGroups.value.size) return overrideGroups.value;

  return groups.value;
});
</script>

<template>
  <ul class="navigation-items">
    <NavigationGroup :groups="finalGroups" :group-key="ROOT" />
  </ul>
</template>

<style scoped>
.navigation-items {
  flex: 1 1 auto;
  padding: 14px;

  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
