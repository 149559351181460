import { ref } from 'vue';
import { ExtensionPoint } from '@mfl/framework';

import {
  NavigationBarMode,
  NavigationComponent,
  NavigationItem,
} from './api.types.js';

export type { NavigationComponent, NavigationItem };
export { NavigationBarMode };

/**
 * Custom navigation components\
 *
 * The navigation items (the accordion part) are also added to this extension point\
 * One can register a component with a priority lower than `NavigationItems` to be displayed below\
 * Registration is [here](core-navigation-mfe.ts)
 */
export const navigationComponents = new ExtensionPoint<NavigationComponent>();

/**
 * The global level navigation items - an accordion-style main menu\
 * Multiple levels are supported by using the `parentKey` field
 *
 * Multiple MFEs can register into the same parent
 */
export const navigationItems = new ExtensionPoint<NavigationItem>();

/**
 * Overrides the global navigation items\
 * Make sure to unregister all items once done\
 * (the register function returns an `unsub` function for each added item)
 *
 * Used for "drilldown"/zen-mode type of user experience
 */
export const navigationItemsOverride = new ExtensionPoint<NavigationItem>();

/**
 * Display state of the navigation bar
 *
 * Can be set to show/hide the panel\
 * Custom components should bind to it and modify their layout accordingly
 *
 * *Note about implementation:\
 * Not too happy with using a vue construct (`ref`)\
 * Should consider creating a `State` construct
 */
export const navigationBarMode = ref<NavigationBarMode>(
  NavigationBarMode.FullView
);
