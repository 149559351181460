<script setup lang="ts">
import Error500 from '../assets/error-500.svg';
import GenericError from './generic-error.vue';
import strings from '../error.strings';

const reload = () => window.location.reload();
</script>

<template>
  <GenericError
    aid="ERROR_500"
    :img-src="Error500"
    :img-alt="strings.somethingWentWrong"
    :title="strings.error500Title"
    :description="strings.error500Description"
    :button-text="strings.reload"
    @click="reload"
  />
</template>
