export default {
  notFound: 'Not Found',
  error404Title: 'Oops! Page Not Found (404)',
  error404Description: `We couldn’t find the page you’re looking for. Please go to the homepage or
    contact support if you need further assistance.`,
  goToHomepage: 'Go to homepage',
  unauthorized: 'Unauthorized',
  error401Title: 'Access Denied (401 Unauthorized)',
  error401Description: `You are not authorized to view this page.
   You can contact your account admin to request the necessary permissions.`,
  somethingWentWrong: 'Something Went Wrong',
  error500Title: 'Oops! Something Went Wrong',
  error500Description: `The page failed to load.
   Please refresh the page, check your connection, or contact support if the problem continues.`,
  reload: 'Reload',
};
