<script setup lang="ts">
import { ref } from 'vue';
import isUrl from 'is-url';
import {
  WsInput,
  WsButton,
  IDialog,
  WsDialogActions,
  WsDialogSection,
  WsDialogForm,
  WsToggle,
} from '@mfl/common-components';
import { linksGateway, Link } from '@wisestamp/links-gateway-sdk';

import strings from './links.strings';

const props = defineProps<{
  dialog: IDialog<Link | undefined, Link>;
}>();

const busy = ref(false);
const link = ref<Link>({
  id: '',
  title: '',
  redirectUrl: '',
  slug: '',
  type: '',
});
const shouldSendNotifications = ref<boolean>(false);

if (props.dialog.input) {
  link.value = props.dialog.input;
  shouldSendNotifications.value = !!link.value.notificationEmail;
}

const urlHostName = ref('');

async function save() {
  busy.value = true;
  try {
    if (link.value.id) await updateLink();
    else await createLink();
  } catch (e) {
    // TODO: inform the user of the failure and allow him/her to try again
    console.error('error saving link', e);
  } finally {
    busy.value = false;
  }
}

async function createLink() {
  const res = await linksGateway.create({
    link: {
      title: (link.value.title ||= urlHostName.value),
      redirectUrl: link.value.redirectUrl,
      slug: link.value.slug,
    },
    shouldSendNotifications: shouldSendNotifications.value,
  });

  if (res.success) {
    props.dialog.close(res.link);
  } else {
    console.error('create link failed', res.error);
    // TODO: inform the user
  }
}

async function updateLink() {
  const res = await linksGateway.update({
    id: link.value.id,
    redirectUrl: link.value.redirectUrl,
    title: (link.value.title ||= urlHostName.value),
    slug: link.value.slug,
    shouldSendNotifications: shouldSendNotifications.value,
    type: link.value.type,
  });

  if (res.success) props.dialog.close(res.link);
  else {
    console.error('failed to update link', res.error);
    // TODO: inform the user
  }
}

async function validateSlug(val: string) {
  if (!val) return true;

  if (!/^[a-zA-Z0-9-]{1,20}$/.test(val)) {
    return strings.slugValidationMessage;
  }

  try {
    const res = await linksGateway.isSlugExists({ slug: val });

    return (
      !res.exists ||
      (link.value.id && res.linkId === link.value.id) ||
      strings.slugUniqueMessage
    );
  } catch {
    return strings.generalError;
  }
}

function validateURL(val: string) {
  if (!val) return strings.addUrl;

  let urlString = val.trim();

  if (!/^https?:\/\//i.test(urlString)) {
    // If the URL is invalid and doesn't contain a protocol, try adding 'http://'
    urlString = 'http://' + urlString;
  }

  link.value.redirectUrl = urlString;

  if (isUrl(urlString)) {
    try {
      extractHostName(urlString);
      return true;
    } catch {
      return strings.urlIsInvalid;
    }
  }

  // If the protocol exists but the URL is still invalid, return an error
  return strings.urlIsInvalid;
}

function extractHostName(urlString: string) {
  const url = new URL(urlString);
  urlHostName.value = url.hostname;
  link.value.title ||= url.hostname;
}
</script>

<template>
  <WsDialogForm @submit="save">
    <WsDialogSection class="link-form-section">
      <div>
        <label for="LINK_DIALOG_URL">
          {{ strings.urlLabel }}
        </label>
        <WsInput
          v-model="link.redirectUrl"
          aid="LINK_DIALOG_URL"
          debounce="500"
          size="md"
          :placeholder="strings.urlPlaceholder"
          autofocus
          :rules="[validateURL]"
          :disabled="link.type === 'survey'"
        ></WsInput>
      </div>
      <div>
        <label for="LINK_DIALOG_TITLE">
          {{ strings.titleLabel }}
        </label>
        <WsInput
          v-model="link.title"
          aid="LINK_DIALOG_TITLE"
          :placeholder="strings.titlePlaceholder"
          size="md"
          :disabled="link.type === 'survey'"
        />
      </div>
      <div>
        <label for="LINK_DIALOG_SLUG">
          {{ strings.slugLabel }}
        </label>
        <div class="link-slug-section">
          <WsInput
            class="link-slug-disabled-input"
            aid="LINK_DIALOG_URL_PREFIX"
            size="md"
            model-value="wise.link"
            disabled
          />
          <WsInput
            v-model="link.slug"
            aid="LINK_DIALOG_SLUG"
            debounce="500"
            size="md"
            :placeholder="strings.slugPlaceholder"
            :rules="[validateSlug]"
          />
        </div>
      </div>
      <div class="link-alerts-section">
        <div class="alerts-label">
          Turn on link alerts
          <p>We will notify you by email with every new click</p>
        </div>
        <WsToggle
          v-model="shouldSendNotifications"
          aid="LINK_DIALOG_NOTIFICATIONS_TOGGLE"
        ></WsToggle>
      </div>
    </WsDialogSection>

    <WsDialogActions>
      <WsButton
        label="Cancel"
        color="gray-400"
        variant="text"
        aid="LINK_DIALOG_CANCEL_BUTTON"
        @click="props.dialog.close()"
      />
      <WsButton
        class="submit-button"
        type="submit"
        color="primary"
        aid="LINK_DIALOG_SUBMIT_BUTTON"
        :label="strings.dialogSaveButton"
        :loading="busy"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>

<style scoped lang="scss">
.link-form-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 560px;
  width: 560px;
  font-size: 13px;
  padding: 24px;

  label > :first-child > * {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.link-slug-section {
  > .q-field--disabled:first-child {
    max-width: 140px;
    --ws-input-border-radius: 3px 0 0 3px;

    > :first-child {
      > :first-child::before {
        background-color: rgb(var(--color-gray-200));
        color: rgb(var(--color-gray-400));
      }
    }
  }

  > :nth-child(2) {
    width: 100%;
    --ws-input-border-radius: 0 3px 3px 0;
  }

  display: flex;
}

.submit-button {
  margin-left: 14px;
}

.link-alerts-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .alerts-label {
    display: flex;
    flex-direction: column;
    color: rgb(var(--color-gray-500));

    p {
      color: rgb(var(--color-gray-400));
    }
  }
}

@media only screen and (max-width: 600px) {
  .link-form-section {
    width: auto;
  }
}
</style>
