<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_6854_502)">
      <path
        d="M6 1.125C7.29293 1.125 8.53291 1.63861 9.44715 2.55285C10.3614 3.46709 10.875 4.70707 10.875 6C10.875 7.29293 10.3614 8.53291 9.44715 9.44715C8.53291 10.3614 7.29293 10.875 6 10.875C4.70707 10.875 3.46709 10.3614 2.55285 9.44715C1.63861 8.53291 1.125 7.29293 1.125 6C1.125 4.70707 1.63861 3.46709 2.55285 2.55285C3.46709 1.63861 4.70707 1.125 6 1.125ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM8.46094 4.85156L6.39844 2.78906C6.17812 2.56875 5.82188 2.56875 5.60391 2.78906L3.54141 4.85156C3.32109 5.07188 3.32109 5.42812 3.54141 5.64609C3.76172 5.86406 4.11797 5.86641 4.33594 5.64609L5.4375 4.54453V8.8125C5.4375 9.12422 5.68828 9.375 6 9.375C6.31172 9.375 6.5625 9.12422 6.5625 8.8125V4.54453L7.66406 5.64609C7.88438 5.86641 8.24062 5.86641 8.45859 5.64609C8.67656 5.42578 8.67891 5.06953 8.45859 4.85156H8.46094Z"
        fill="#1E90FA"
      />
    </g>
    <defs>
      <clipPath id="clip0_6854_502">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
