<script setup lang="ts">
import { WsInput } from '@mfl/common-components';

const model = defineModel<string>({ required: true });

const props = defineProps<{
  error?: string;
}>();
</script>
<template>
  <div class="edit-organization-name-control">
    <ws-input
      v-model.trim="model"
      aid="EDIT_ORGANIZATION_NAME_INPUT"
      :error="props.error"
      size="md"
      class="edit-organization-input"
    />
  </div>
</template>
