import {
  EmployeeGroup,
  employeeGroupGateway,
} from '@msl/employee-group-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';

//TODO: get rid of this manger in future
// the main goal of this manager is not make sure settingsGateway is initialized before being used
class GroupsManager {
  private async assertInitialized() {
    await employeeGroupGateway.init(auth4Sdks());

    return employeeGroupGateway;
  }

  public async getGroupList(domainId: string): Promise<EmployeeGroup[] | []> {
    const gw = await this.assertInitialized();

    const { result } = await gw.getGroupList({ domainId });
    if (!result) {
      throw new Error('Get group list error');
    }

    return result;
  }
}

export const groupsManager = new GroupsManager();
