<script setup lang="ts">
import { WsSelect, WsSelectOption } from '@mfl/common-components';
import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';
import { useNavBarHierarchy } from './navigation-bar-heirarchy';

const modes = [
  {
    text: NavigationBarMode[NavigationBarMode.FullView],
    value: NavigationBarMode.FullView,
  },
  {
    text: NavigationBarMode[NavigationBarMode.MinimalView],
    value: NavigationBarMode.MinimalView,
  },
  {
    text: NavigationBarMode[NavigationBarMode.Hidden],
    value: NavigationBarMode.Hidden,
  },
  {
    text: NavigationBarMode[NavigationBarMode.Locked],
    value: NavigationBarMode.Locked,
  },
];

useNavBarHierarchy();
</script>

<template>
  <div class="navigation-tester">
    <h3><b>Navigation bar tester</b></h3>
    <WsSelect
      v-model="navigationBarMode"
      :option-label="(val) => NavigationBarMode[val]"
      :option-key="(val) => val"
      label="Navbar mode"
      aid="NAV_BAR_MODE_SELECT"
    >
      <WsSelectOption
        v-for="mode in modes"
        :key="mode.value"
        :value="mode.value"
      >
        {{ mode.text }}
      </WsSelectOption>
    </WsSelect>
  </div>
</template>

<style lang="css" scoped>
.navigation-tester {
  max-width: 90%;
  align-self: center;
  border: 1px dotted black;
  padding: 8px;
  display: flex;
  flex-direction: column;
}
</style>
