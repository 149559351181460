<script setup lang="ts">
import { QInput, QInputProps } from 'quasar';
import { ref } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const model = defineModel<string>();

withDefaults(
  defineProps<{
    autofocus?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    error?: string;
    id?: string;
    label?: string;
    placeholder?: string;
    maxlength?: number;
    rules?: Array<(v: string) => boolean | string | Promise<boolean | string>>;
    size: 'sm' | 'md' | 'lg';
    type?: string;
    aid: string;
  }>(),
  {
    size: 'md',
  }
);

const emit = defineEmits<{
  focus: [event: FocusEvent];
  blur: [event: FocusEvent];
  clear: [];
}>();

const qInput = ref<QInput | null>(null);

defineExpose({
  focus: () => qInput.value?.focus(),
});
</script>

<template>
  <QInput
    ref="qInput"
    v-model="model"
    outlined
    :autofocus="autofocus"
    :clearable="clearable"
    clear-icon="fa-solid fa-circle-xmark text-[1rem] text-gray-300 opacity-100"
    :disable="disabled"
    :readonly="readonly"
    :error="!!error"
    :error-message="error"
    no-error-icon
    :for="id"
    :label="label || undefined"
    :placeholder="placeholder"
    :maxlength="maxlength"
    :rules="rules"
    lazy-rules
    :type="type as QInputProps['type']"
    class="ws-input"
    :class="{
      'ws-input--sm': size === 'sm',
      'ws-input--md': size === 'md',
      'ws-input--lg': size === 'lg',
      'cursor-not-allowed': readonly,
    }"
    :input-class="{
      'cursor-not-allowed': readonly,
    }"
    :aid="aid"
    @update:model-value="qInput?.resetValidation()"
    @focus="emit('focus', $event as FocusEvent)"
    @blur="emit('blur', $event as FocusEvent)"
    @clear="emit('clear')"
  >
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>

    <template v-if="$slots.append || readonly" #append>
      <span v-if="readonly" class="fa-solid fa-lock text-base text-gray-200" />
      <slot v-else name="append" />
    </template>
  </QInput>
</template>

<style scoped lang="scss">
.ws-input {
  font-size: var(--ws-input-font-size);

  &--sm {
    --ws-input-font-size: 0.75rem;
    --ws-input-height: 36px;
    --ws-input-border-radius: 6px;
  }

  &--md {
    --ws-input-font-size: 0.8125rem;
    --ws-input-height: 45px;
    --ws-input-border-radius: 4px;
  }

  &--lg {
    --ws-input-font-size: 0.875rem;
    --ws-input-height: 48px;
    --ws-input-border-radius: 4px;
  }

  &.q-field--readonly :deep(.q-field__control:before) {
    border-style: solid;
  }

  :deep(.q-field__control) {
    height: var(--ws-input-height);
    border-radius: var(--ws-input-border-radius);

    &:before {
      border: 1px solid rgb(var(--color-gray-200));
    }

    &:hover:before {
      border-color: rgb(var(--color-gray-200));
    }
  }

  :deep(.q-field__marginal) {
    height: var(--ws-input-height);
  }

  :deep(.q-field__label) {
    font-size: calc(0.625rem / 0.75);
    top: 14px;
  }

  &.q-field--float :deep(.q-field__label) {
    font-weight: 600;
  }

  &.q-field--error {
    :deep(.q-field__label) {
      // Removes shake animation
      animation: none;
    }

    :deep(.q-field__control:after) {
      border-width: 1px;
    }
  }

  :deep(.q-field__bottom) {
    padding-top: 4px;
  }
}
</style>
