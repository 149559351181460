import { ref } from 'vue';
import { routeChangedEvent } from '@mfl/framework';

/**
 * A reactive for the current route\
 * Used my menu items to assess whether they are active
 */
export const currentRoute = ref(location.pathname);

routeChangedEvent.subscribe(() => {
  currentRoute.value = location.pathname;
});
