import { debounce } from 'lodash';

import { WEBAPP_BASE_URL } from '@mfl/platform-shell';

import strings from './settings-strings';

export const getWebAppUrl = (path: string = '/employees') => {
  const normalizedPath = path.startsWith('/') ? path.substring(1) : path;
  return `${WEBAPP_BASE_URL}/${normalizedPath}`;
};

export const createDebouncedHandler = <TValue>(
  fn: (v: TValue) => void,
  timeout: number = 600
) =>
  debounce((val: TValue) => {
    fn(val);
  }, timeout);

export const getCurrentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getAllTimezoneValues = () =>
  Intl.supportedValuesOf('timeZone').sort();

export const getTimezoneWithGMTOffset = (tz: string) => {
  const [localTime, ampm, gmt] = new Date()
    .toLocaleString('en-US', {
      timeZone: tz,
      timeZoneName: 'shortOffset',
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(' ');

  const [_tzCountry, tzCity] = tz.split('/');

  return `${tzCity} ${localTime}${ampm} (${gmt})`;
};

const runValidationRules = (
  rules: Array<() => string | undefined>
): string | undefined => {
  for (const rule of rules) {
    const msg = rule();
    if (msg) {
      return msg;
    }
  }
  return undefined;
};

const validateNotEmpty = (s: string, msg?: string) => {
  if (!s) {
    return msg || strings.fieldIsRequed;
  }
  return undefined;
};

const validateNotUrl = (s: string, msg?: string) => {
  if (containsUrl(s)) {
    return msg || strings.canNotContainUrl;
  }

  return undefined;
};

const URL_RX =
  /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

const validateUrl = (v: string, msg?: string) => {
  const val = v.trim();
  if (!val) return undefined;
  if (!URL_RX.test(val)) {
    return msg || strings.validUrlError;
  }

  return undefined;
};

const DEFAULT_MAX_LENGTH = 40;
const validateMaxLength = (s: string, maxL: number = 40, msg?: string) => {
  if (s.length > maxL) {
    return msg
      ? msg.replace('{maxLength}', maxL.toString())
      : strings.maxLengthMsg.replace('{maxLength}', maxL.toString());
  }

  return undefined;
};

export const validateEmailSubject = (v: string) => {
  return runValidationRules([
    () =>
      validateNotEmpty(
        v,
        strings.employeePortalInviteEmailSubjectIsRequiredErrorMsg
      ),
    () =>
      validateNotUrl(
        v,
        strings.employeePortalInviteEmailSubjectContainsUrlErrorMsg
      ),
    () =>
      validateMaxLength(
        v,
        DEFAULT_MAX_LENGTH,
        strings.employeePortalInviteEmailSubjectTooLongErrorMsg
      ),
  ]);
};

export const validateInviteSender = (v: string) => {
  return runValidationRules([
    () =>
      validateNotEmpty(
        v,
        strings.employeePortalInviteEmailSenderNameIsRequiredErrorMsg
      ),
    () =>
      validateNotUrl(
        v,
        strings.employeePortalInviteEmailSenderNameContainsUrlErrorMsg
      ),
    () =>
      validateMaxLength(
        v,
        DEFAULT_MAX_LENGTH,
        strings.employeePortalInviteEmailSubjectTooLongErrorMsg
      ),
  ]);
};

export const validateDomainName = (v: string) => {
  return runValidationRules([() => validateUrl(v)]);
};

export const validateOrganizationName = (v: string) => {
  return runValidationRules([() => validateNotEmpty(v)]);
};

export const validateGoogleAnalyticsField = (v: string) => {
  return runValidationRules([() => validateMaxLength(v)]);
};

const CONTAINS_URL_RX = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?'
);

export const containsUrl = (s: string) => {
  return CONTAINS_URL_RX.test(s);
};

//TODO: change unknown?
export const isEmpty = (s: unknown) => {
  return !s;
};

export const isInBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  return v.startsWith(open) && v.endsWith(close);
};

export const stripBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  // if (v.length < open.length + close.length) {
  //   return v;
  // }

  let stripped = v;
  if (stripped.startsWith(open)) {
    stripped = stripped.substring(open.length);
  }

  if (stripped.endsWith(close)) {
    stripped = stripped.substring(0, stripped.length - close.length);
  }
  return stripped;
};

export const ensureWrappedWithBraces = (
  v: string,
  open: string = '{{',
  close: string = '}}'
) => {
  return `${open}${stripBraces(v, open, close)}${close}`;
};

//using for dev purposes only
export const delay = (ms: number = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
