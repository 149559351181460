import { CustomOrnamentOptions, NavigationItem } from '../../api.types';

import BetaOrnament from './beta-ornament.vue';
import ProOrnament from './pro-ornament.vue';

/**
 * Convert the built-in ornaments to custom ornament structure
 */
export function normalizeOrnaments(
  item: NavigationItem
): CustomOrnamentOptions[] {
  if (!item.ornaments) return [];

  return item.ornaments.map((o) => {
    if (o.type === 'beta')
      return {
        key: 'BETA',
        component: BetaOrnament,
      };

    if (o.type === 'pro')
      return {
        key: 'PRO',
        component: ProOrnament,
      };

    return o.options as CustomOrnamentOptions;
  });
}
