import { Component } from 'vue';

export enum NavigationBarMode {
  /** Full width display */
  FullView,
  /** AKA: Collapsed */
  MinimalView,
  /** Not shown AT ALL */
  Hidden,
  /** Fully viewed and the user can't minimize it */
  Locked,
}

export type NavigationComponent = {
  /**
   * unique name of the component\
   * will also be used for automation\
   * Use CONSTANT_CASE
   */
  key: string;
  component: Component;
  events?: NavigationComponentEvents;
  props?: NavigationComponentProps;
};

export type NavigationComponentEvents = Record<
  string,
  (payload: unknown) => void
>;
export type NavigationComponentProps = Record<string, unknown>;

export type NavigationItem = {
  /**
   * Unique key for the entry\
   * Also used for automation (aid)\
   * Use CONSTANT_CASE
   */
  key: string;

  /**
   * The text for the item
   */
  text: string;

  /**
   * Navigation route\
   * `@mfl/framework:navigateTo` function is used (internal SPA navigation)
   *
   * if the route is to the old webapp FE use `handler` prop - see comment there
   */
  route?: string;

  /**
   * Item click callback function\
   * Usually you should define either the `route` OR this
   * 
   * return `false` to skip navigation to the route
   * 
   * if the route is to the old webapp FE, your handler should:
   * - call `navigateTo` with the `reload` argument set to true
   * - return false to skip `route`-based navigation\
   * (you should still define the route so the nav-item link behaves correctly - copy and open in new tab)

   * @param item This item
   */
  handler?: (item: NavigationItem) => Promise<boolean>;

  /**
   * FA icon class name; ex. `xmark`\
   * fa-light and fa-regular are added according to whether the item is active\
   * Make sure both are included in the fa kit
   */
  fontAwesomeIcon?: string;

  /**
   * The font-size for the FA icon (in pixels)
   *
   * Default is 20px
   */
  fontAwesomeIconSize?: number;

  /**
   * Path to an image to use for as an icon\
   * Can also be an data URL of a bundled image\
   *
   * ```ts
   * import myImageSvg from './my-image.svg';
   *
   * navigationItems.register({
   *  iconUrl: myImageSvg
   * });
   * ```;
   */
  iconUrl?: string;

  /**
   * Path to an image to used for as an icon when the item is active (user is on the `route`)
   */
  iconUrlActive?: string;

  /**
   * Designates this item to be a sub-item of another
   */
  parentKey?: string;

  /**
   * Decorations to place on the items (after the text)
   */
  ornaments?: Ornament[];
};

export type Ornament = {
  type: 'beta' | 'pro' | 'custom';
  options?: BuiltinOrnamentOptions | CustomOrnamentOptions;
};

/**
 * Options for the `beta` and `pro` ornaments\
 * Might need to create a separate options type for each
 */
export type BuiltinOrnamentOptions = {
  /**
   * This is currently NOT required by product team\
   * We feel it might come in handy for a tooltip or other future reqs
   */
  tooltip: string;
};

export type CustomOrnamentOptions = {
  /**
   * A unique ID for the ornament\
   * Doubles as an `aid`
   */
  key: string;

  /**
   * Component to dynamically create
   */
  component: Component;
};
