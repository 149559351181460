import { type NavigationItem } from '@mfe/core-navigation';

import strings from './global-navigation.strings';
import employeesSvg from './users.svg';
import employeesActiveSvg from './users-active.svg';
import signaturesSvg from './signatures.svg';
import signaturesActiveSvg from './signatures-active.svg';
import connectorsSvg from './connectors.svg';
import connectorsActiveSvg from './connectors-active.svg';

export function getNavigationItems() {
  // TODO: filter according to permissions/entitlements
  return globalNavigationItems;
}

/**
 * These entries point to the old frontend\
 * When a module is migrated to ws1 its entry should be removed from to the modules' plugin
 */
const globalNavigationItems: NavigationItem[] = [
  {
    key: 'EMPLOYEES',
    text: strings.employees,
    iconUrl: employeesSvg,
    iconUrlActive: employeesActiveSvg,
    route: '/employees',
  },
  {
    key: 'SIGNATURES',
    text: strings.signatures,
    iconUrl: signaturesSvg,
    iconUrlActive: signaturesActiveSvg,
    route: '/signatures',
    ornaments: [{ type: 'pro' }],
  },
  {
    key: 'CONNECTORS',
    text: strings.connectors,
    iconUrl: connectorsSvg,
    iconUrlActive: connectorsActiveSvg,
    route: '/connectors',
    ornaments: [{ type: 'beta' }],
  },
];
