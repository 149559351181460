import { settingsManager } from '../settings-manager';
import { DomainSyncAndAutomationModel } from '../settings-types';
import { useSettingsModel } from './use-settings-model';

const domainSyncAutomation = useSettingsModel<DomainSyncAndAutomationModel>({
  getDefaultValues() {
    return {
      sync: {
        syncBySpecificators: false,
        syncSuspended: false,
        dailyEmployeeSync: false,
        autoDelete: false,
        syncAliases: false,
        securityGroupId: '',
        specificators: {
          values: [],
          type: '',
        },
      },
      newEmployees: {
        autoActivate: false,
        defaultGroup: '',
      },
      employeeActivation: {
        autoInject: false,
        autoInvite: false,
      },
    };
  },

  async fetch() {
    const syncSettings = await settingsManager.getDomainSyncAutomationInfo();
    return syncSettings;
  },

  async update(data) {
    await settingsManager.updateDomainSyncAutomationInfo(data);
  },

  validate(_data) {
    return {};
  },
});

export default domainSyncAutomation;
