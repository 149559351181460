import { onMounted, onUnmounted } from 'vue';
import { Unsub } from '@mfl/framework';
import { showLogo, headerComponents } from '@mfe/core-header';

import BackButton from './back-button.vue';

export const registerBackButtonInHeader = () => {
  let unsub: Unsub | undefined;
  onMounted(() => {
    showLogo(false);
    unsub = headerComponents.register({
      key: 'header-back-button',
      component: BackButton,
    });
  });

  onUnmounted(() => {
    showLogo(true);
    if (unsub) unsub();
  });
};
