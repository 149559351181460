<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { RouterView } from 'vue-router';

import { deferLoading, loadingDone } from '@mfl/framework';

import { useShellSetup } from './navigation';
import SideMenu from './components/side-menu/side-menu.vue';

import { sections } from './sections/sections';

import generalDomainInfo from './composables/domain-general-info';
import longActionModel from './composables/long-action-model';

import { registerBackButtonInHeader } from './components/back-button/back-button';
import { MenuItem } from './components/side-menu/side-menu-types';

useShellSetup();

const domainName = computed(() => {
  return generalDomainInfo.isLoading
    ? '...'
    : generalDomainInfo.data.organizationName;
});

const accessibleItems: MenuItem[] = sections
  .filter((s) => !s.checkPermission || s.checkPermission())
  .map((s) => ({
    aid: s.aid,
    text: s.text,
    icons: s.icons,
    route: s.route,
    action: s.action,
  }));

registerBackButtonInHeader();

onBeforeMount(async () => {
  try {
    deferLoading();
    await generalDomainInfo.triggerFetch();
    await longActionModel.triggerFetch();
  } finally {
    loadingDone();
  }
});
</script>

<template>
  <div class="settings-view">
    <div class="settings-side-menu-section">
      <SideMenu
        aid="settings-side-menu"
        :items="accessibleItems"
        :heading="domainName"
        :collapsible="false"
      />
    </div>
    <div class="settings-content-section">
      <RouterView />
    </div>
  </div>
</template>

<style lang="scss">
$height: 100%;
$border-color: var(--Gray-200, #e0e0e0);

%box-sizing-snippet {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.settings-view {
  display: flex;
  flex-flow: row nowrap;

  margin: 0;
  padding: 0;

  height: $height;
  width: 100%;
  @extend %box-sizing-snippet;

  .settings-side-menu-section {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    @extend %box-sizing-snippet;
    border-right: 1px solid $border-color;
  }

  .settings-content-section {
    display: inline-block;
    height: 100%;
    width: 100%;
    @extend %box-sizing-snippet;
  }
}

.state-loading {
  position: relative;
  user-select: none;
  overflow: hidden;
  border-radius: 3px;
}

.state-loading:before {
  --loader-color: #1e90fa;
  animation: loader 1s infinite 0ms linear;

  border-width: 4px;
  border-style: solid;
  border-color: transparent var(--loader-color) var(--loader-color);

  border-radius: 50%;

  content: '';

  width: 30px;
  height: 30px;

  left: 50%;
  top: 50%;

  margin: -15px 0 0 -15px;

  position: absolute;
  z-index: 5;
}

.state-loading:after {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}

@keyframes loader {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.text-danger {
  color: #f54545;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-information {
  color: #828282;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
</style>
