import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';
import { onMounted, onUnmounted } from 'vue';

/**
 * Temporary\
 * Hide the core-navigation bar until settings migrates to using it
 */
export function useShellSetup() {
  let oldMode: NavigationBarMode | undefined;
  onMounted(() => {
    oldMode = navigationBarMode.value;
    navigationBarMode.value = NavigationBarMode.Hidden;
  });

  onUnmounted(() => {
    // Go back to the mode before we hidden the navbar
    if (oldMode) navigationBarMode.value = oldMode;
  });
}
