import { Priority } from '@mfl/framework';

import { navigationComponents } from '../api';
import NavigationItems from './navigation-menu.vue';

export function initMenu() {
  // Instead of hard coding the items into the main component, use the dynamic components mechanism
  navigationComponents.register(
    {
      component: NavigationItems,
      key: 'MAIN_NAV_ITEMS',
    },
    {
      priority: Priority.Low - 5000,
    }
  );
}
