import type { Directive } from 'vue';
const loadingDirective: Directive<HTMLElement, boolean> = function (
  el,
  binding
) {
  const runDirective = async () => {
    if (binding.value) {
      // this may overlap with #platform-frame-loader-overlay;
      // temporary solution: platform-frame-loader-overlay has a greater z-index css value;
      el.classList.add('state-loading');
    } else {
      el.classList.remove('state-loading');
    }
  };

  runDirective();
};

export default loadingDirective;
