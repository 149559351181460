import { IntegrationType } from '@msl/settings-gateway-sdk';
import { settingsManager } from '../settings-manager';
import { useRequest } from './use-request';

const defaultValues = {
  domainData: {
    macros: {
      company: [],
      employee: [],
    },
    customFields: [],
  },
  domainName: '',
  organizationName: '',
  connectedBy: '',
  id: '',
  integrationError: '',
  integrationType: IntegrationType.OTHER,
  isIntegrated: false,
  requireFinishIntegration: false,
  securityGroupName: '',
};

const generalDomainInfo = useRequest(async () => {
  return await settingsManager.getGeneralDomainInfo();
}, defaultValues);

export default generalDomainInfo;
