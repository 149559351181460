import { settingsManager } from '../settings-manager';
import { LongAction } from '@msl/settings-gateway-sdk';
import { useIntervalRequest } from './use-interval-request';

export enum ACTIONS_TYPES {
  SPECIFICATORS = 'specificators',
  SYNC = 'sync',
}

export enum LongActionStatus {
  IN_PROGRESS = 'in progress',
}

export const hasActionsInProgress = (longActions: Array<LongAction>) => {
  // only for fetch specificators and sync
  return longActions.some(({ status, actionType }) => {
    return (
      status === LongActionStatus.IN_PROGRESS &&
      (actionType === ACTIONS_TYPES.SYNC ||
        actionType === ACTIONS_TYPES.SPECIFICATORS)
    );
  });
};

export const isSyncRunning = (longActions: Array<LongAction>) => {
  return longActions.some(({ status, actionType }) => {
    return (
      status === LongActionStatus.IN_PROGRESS &&
      actionType === ACTIONS_TYPES.SYNC
    );
  });
};

const longActionsModel = useIntervalRequest({
  async fetch() {
    return settingsManager.getLongActions();
  },
  getDefaultData() {
    return [];
  },
  done(data) {
    return !hasActionsInProgress(data);
  },
});

export default longActionsModel;
