import { Priority } from '@mfl/framework';
import {
  NavigationBarMode,
  navigationBarMode,
  navigationComponents,
  navigationItems,
} from '@mfe/core-navigation';

import { getNavigationItems } from './global-navigation-items';
import NavigationDomains from './domain-selector.vue';

/**
 * Setup navigation items and custom components\
 * These items are temporary for the migration period
 */
export function doNavbarSetup() {
  // TEMP: Hide the navbar until we fully sync it with webapp navbar
  navigationBarMode.value = NavigationBarMode.Hidden;

  navigationComponents.register(
    {
      key: 'DOMAIN_SELECTOR',
      component: NavigationDomains,
    },
    {
      priority: Priority.High,
    }
  );

  getNavigationItems().forEach((item) => navigationItems.register(item));
}
