import { registerApp } from '@mfl/framework';
import { currentUser } from '@mfl/platform-shell';

// Repeating the constants for the puspose of avoiding an additional
// runtime bundle for a `settings-types-BVySIyR3.js` (0.82 kB)
const baseRoute = '/v1/settings';
const DOMAIN_MANAGE = 'domain.manage';

export async function register() {
  //when uses is in personal-signatures mode there is no domain with settings
  // as well as if user has no permission
  if (
    currentUser.isAllowedTo(DOMAIN_MANAGE) &&
    currentUser.domainId !== 'personal-signatures'
  ) {
    await registerApp('settings', baseRoute);
  }
  // TODO: register to main navigation
}
