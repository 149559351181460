<script setup lang="ts">
import { computed } from 'vue';
import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';

import translations from './domain-selector.strings';

// React to navigation bar mode change (example)
const isMinimized = computed(
  () => navigationBarMode.value === NavigationBarMode.MinimalView
);
</script>
<template>
  <div class="platform-domains">
    <Transition name="slide-up" mode="out-in">
      <span v-if="isMinimized">{{ 'minimized' }}</span>
      <span v-else> {{ translations.welcome }}</span>
    </Transition>
  </div>
</template>
<style scoped>
.platform-domains {
  border: 1px dashed black;
  overflow: hidden;
  height: 23px;

  /* avoid getting bloated of shrunk */
  flex: 0 0 auto;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
