<script setup lang="ts">
import { VNode } from 'vue';
import vLoading from '../../directives/loading';

const props = withDefaults(
  defineProps<{
    isLoading: boolean;
  }>(),
  {
    isLoading: false,
  }
);

const slots = defineSlots<{
  alert?: () => VNode;
  header?: () => VNode;
  body?: () => VNode;
  actions?: () => VNode;
}>();
</script>
<template>
  <div class="ws-section">
    <div v-if="slots.header" class="ws-section-header">
      <slot name="header" />
    </div>
    <div v-loading="props.isLoading" class="ws-section-content">
      <div class="ws-section-body">
        <slot name="body" />
      </div>
    </div>
    <div class="ws-section-actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<style lang="scss">
:root {
  --section-content-h-padding: 24px;
  --section-content-v-padding: 24px;
  --section-body-max-width: 900px;
}

.ws-section {
  margin: 0;
  border: none;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .ws-section-loading-content {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .ws-section-header {
    color: var(--Gray-500, #2d2e30);
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    padding: var(--section-content-v-padding) var(--section-content-v-padding) 0;
  }

  .ws-section-content {
    display: flex;
    flex-direction: column;
    gap: 14px;

    width: 100%;
    padding: var(--section-content-v-padding) var(--section-content-h-padding);
    overflow: auto;
    height: 100%;

    .ws-section-body {
      margin: 0;
      box-sizing: border-box;
      border: none;

      max-width: var(--section-body-max-width);
    }
  }

  .ws-section-actions {
    display: flex;
    justify-content: flex-start;
    gap: 8px;

    padding: 12px 24px;
    align-items: center;

    border-top: 1px solid var(--Gray-200, #e0e0e0);
  }
}

@media only screen and (max-width: 748px) {
  :root {
    --section-content-h-padding: 5px;
    --section-content-v-padding: 5px;
  }
}
</style>
