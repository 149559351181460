<script setup lang="ts">
import { WsButton } from '@mfl/common-components';

defineProps<{
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  buttonText: string;
}>();

const emit = defineEmits<{
  click: [event: PointerEvent];
}>();
</script>

<template>
  <div class="container">
    <img :src="imgSrc" :alt="imgAlt" height="160" />

    <h1 class="text-3xl text-weight-medium">{{ title }}</h1>
    <p class="text-xl">
      {{ description }}
    </p>

    <WsButton size="lg" aid="ACTION_BUTTON" @click="emit('click', $event)">{{
      buttonText
    }}</WsButton>
  </div>
</template>

<style scoped>
.container {
  display: grid;
  place-items: center;
  max-width: 578px;
  text-align: center;
}

img {
  margin-bottom: 60px;
  height: 160px;
}

h1 {
  margin-bottom: 14px;
}

p {
  margin-bottom: 40px;
}
</style>
